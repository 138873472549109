import { useCookies } from "react-cookie";
import Image from "@/components/Atoms/Image";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { MultiPurposeParagraph } from "@/components/Atoms/Paragraph";
import { MultiPurposeTitle } from "@/atoms/Title";
import styles from "./index.module.scss";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { useTranslation } from "next-i18next";

interface Props {
  siteConfig: any;
}

export default function AgeGate({ siteConfig }: Props) {
  const [cookies, setCookie, removeCookie] = useCookies(["age-gate"]);

  const yesClick = () => {
    setCookie("age-gate", 1, { path: "/" });
    window.location.reload();
  };

  const noClicked = () => {
    window.location.href = siteConfig.ageGateRedirectUrl;
  };

  if (siteConfig.logoPicker) {
    var logo = siteConfig.headerMenu.logo;
  } else {
    var logo = siteConfig.headerMenu.logoColour;
  }

  const { t } = useTranslation();
  return (
    <div
      style={{ background: siteConfig.agBackgroundColor?.color?.hex }}
      className={` ${styles.ageGatePage} relative mx-auto  flex flex-col items-center justify-center`}
    >
      <div className="w-full">
        <div className=" mx-auto max-w-[60rem] relative z-10">
          <div className={` lg:pt-6 lg:pb-6  flex justify-center `}>
            <Image className="h-[5rem] lg:h-[8rem] max-w-[15rem]" data={logo} />
          </div>

          <div className="flex flex-col gap-4 lg:gap-8 px-12 items-center mt-6 md:mt-0 ">
            {siteConfig.ageGateWelcome && (
              <div className="flex flex-col items-center w-fit">
                {" "}
                <MultiPurposeTitle
                  layout={"center"}
                  tag="h2"
                  type="default"
                  data={siteConfig.ageGateWelcome}
                  color={siteConfig.agTaglineColor?.color}
                />
                {siteConfig.addTitleUnderline && (
                  <>
                    {siteConfig.customUnderlineAsset?.img ? (
                      <CustomUnderline
                        shape={siteConfig.customUnderlineAsset?.img}
                        color={siteConfig.underlineColor?.color}
                      />
                    ) : (
                      <Underline color={siteConfig.underlineColor?.color} />
                    )}
                  </>
                )}
              </div>
            )}

            <MultiPurposeParagraph
              align={"center"}
              type="featured"
              data={siteConfig.ageGateTitle}
              color={siteConfig.agHeadingColor?.color}
            />
            <MultiPurposeParagraph
              align={"center"}
              type="default"
              data={siteConfig.ageGateContent}
              color={siteConfig.agHeadingColor?.color}
            />
            <MultiPurposeParagraph
              align={"center"}
              type="default"
              transform={"uppercase"}
              weight={"bold"}
              data={siteConfig.ageGateQuestion}
              color={siteConfig.agBodyColor?.color}
            />

            <div className="flex gap-2 md:gap-12 items-center w-auto flex-row justify-center   md:pb-12">
              <ButtonMaster
                anim={""}
                onClick={yesClick}
                type={siteConfig.ageGateButton[0]?.buttonStyle}
                cta={siteConfig.ageGateButton[0]?.typeColor?.color}
                color={siteConfig.ageGateButton[0]?.labelColor?.color}
                customIcon={siteConfig.ageGateButton[0]?.icon?.img}
                customColor={siteConfig.ageGateButton[0]?.iconColor?.color}
                target={``}
                link={null}
              >
                {t("yes")}
              </ButtonMaster>
              <ButtonMaster
                anim={""}
                onClick={noClicked}
                type={siteConfig.ageGateButton[0]?.buttonStyle}
                cta={siteConfig.ageGateButton[0]?.typeColor?.color}
                color={siteConfig.ageGateButton[0]?.labelColor?.color}
                customIcon={siteConfig.ageGateButton[0]?.icon?.img}
                customColor={siteConfig.ageGateButton[0]?.iconColor?.color}
                target={``}
                link={null}
              >
                {t("no")}
              </ButtonMaster>
            </div>
          </div>
        </div>
        <FullBackgroundImage data={siteConfig.ageGateBackgroundImage?.url} />
      </div>
    </div>
  );
}
